const defaultAchievements = {
    bets: {
        bets: {
            icon: {
                lvl0: 'coin_lvl_1.png',
                lvl1: 'coin_lvl_2.png'
            },
            description: {
                lvl0: 'Сделал первую ставку!',
                lvl1: 'Сделал value ставок!'
            }
        },
        winnerBets: {
            icon: {
                lvl0: 'coin_w_lvl_1.png',
                lvl1: 'coin_w_lvl_3.png'
            },
            description: {
                lvl0: 'Сделал первую победную ставку!',
                lvl1: 'Сделал value победных ставок!'
            }
        }
    },
    bankwinners: {
        weekBank: {
            icon: {
                lvl0: 'top-5.png',
                lvl1: 'top-5.png'
            },
            description: {
                lvl0: 'Попал в ТОП-5 рейтинга за неделю!',
                lvl1: 'Попал в ТОП-5 рейтинга за неделю value раза!'
            }
        },
        weekBank_1: {
            icon: {
                lvl0: 'medal.png',
                lvl1: 'medal.png'
            },
            description: {
                lvl0: 'Занял ТОП-1 в рейтинге за неделю!',
                lvl1: 'Занял ТОП-1 в рейтинге за неделю value раза!'
            }
        },
        monthBank: {
            icon: {
                lvl0: 'top-10.png',
                lvl1: 'top-10.png'
            },
            description: {
                lvl0: 'Попал в ТОП-10 рейтинга за месяц!',
                lvl1: 'Попал в ТОП-10 рейтинга за месяц value раза!'
            }
        },
        monthBank_1: {
            icon: {
                lvl0: 'medal.png',
                lvl1: 'medal.png'
            },
            description: {
                lvl0: 'Занял ТОП-1 в рейтинге за месяц!',
                lvl1: 'Занял ТОП-1 в рейтинге за месяц value раза!'
            }
        },
        seasonBank: {
            icon: {
                lvl0: 'top-10.png',
                lvl1: 'top-10.png'
            },
            description: {
                lvl0: 'Попал в ТОП-10 рейтинга за сезон!',
                lvl1: 'Попал в ТОП-10 рейтинга за сезон value раза!'
            }
        },
        seasonBank_1: {
            icon: {
                lvl0: 'medal.png',
                lvl1: 'medal.png'
            },
            description: {
                lvl0: 'Занял ТОП-1 в рейтинге за сезон!',
                lvl1: 'Занял ТОП-1 в рейтинге за сезон! value раза!'
            }
        }
    }
}

export { defaultAchievements }