import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import './style.scss'

const InfoBar = ({ estimatedBets, userId }) => {
    const { user } = useParams()
    const history = useHistory()

    const path = window.location.pathname.split('/')[2]

    return  <div className='info-bar'>
                <div className='glass' onClick={() => history.push(`/${userId}`)}>
                    <span>ещё прогнозов на этой неделе</span>
                    <div className='estimated-bar'>
                        <div
                            className='estimated-value'
                            style={{width: `${(estimatedBets/10)*100}%`}}
                        >
                            <div className='estimated-indicator'>{estimatedBets}</div>
                        </div>
                    </div>
                </div>
                <div className={'glass'+(path === 'history' ? ' current' : '')} onClick={() => history.push(`/${userId}/history`)}>
                    <span>история</span>
                    <img src={require('../assets/hourglass.png')} />
                </div>
                <div className={'glass'+(path === 'achievements' ? ' current' : '')} onClick={() => history.push(`/${userId}/achievements`)}>
                    <span>награды</span>
                    <img src={require('../assets/crown.png')} />
                </div>
                <div className={'glass'+(path === 'ratings' ? ' current' : '')} onClick={() => history.push(`/${userId}/ratings`)}>
                    <span>рейтинги</span>
                    <img src={require('../assets/trophy.png')} />
                </div>
            </div>
}

export default InfoBar
