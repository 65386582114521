import React, {useState} from 'react'
import Lottie from 'react-lottie'
import * as animationCoin from '../assets/gold.json'
import * as animationFire from '../assets/fire.json'

import './style.scss'

const coinOptions = {
    loop: true,
    autoplay: true,
    animationData: animationCoin,
    speed: 0.4
}

const fireOptions = {
    loop: true,
    autoplay: true,
    animationData: animationFire,
    speed: 0.5
}

const Top = ({ player, bestBet }) => {
    const [tooltip, setTooltip] = useState(false)
    return  <div className="top">
                <div className="avatar smooth">
                    <div className="inner smooth">
                    </div>
                </div>

                <div className='profile smooth'>
                    <span>{player.profile.name}</span>
                    <div className='balance smooth'>
                        <Lottie
                            options={coinOptions}
                            width={44}
                            height={44}
                        />

                        <span>{player.balance}</span>
                    </div>
                </div>
                {
                    bestBet ?
                    <div
                        className='coefficient'
                        onClick={() => setTooltip(!tooltip)}
                    >
                        <Lottie
                            options={fireOptions}
                            width={20}
                            height={20}
                        />
                        {tooltip ? <span className={'tooltip'}>максимальный коэффициент</span> :
                            <span>{bestBet?.factor}</span>
                        }
                    </div> : null
                }
            </div>
}

export default Top
