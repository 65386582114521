import React, {useState, useEffect, useContext, useRef} from 'react'
import { useHistory } from 'react-router-dom'
import AppContext from '../ctx'

import Loader from '../Loader'

import { Button } from 'primereact/button'

import './style.scss'
import './medias.scss'

import axios from 'axios'
import { ENDPOINT } from '../env'

import ym from 'react-yandex-metrika'

import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

const types = {
    week: 'Эта неделя',
    month: `Рейтинг ${moment().format('D MMMM').split(' ')[1]}`,
    season: 'Сезонный'
}

const toDigits = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}

const Ratings = () => {
    const [data, setData] = useState(null)
    const [type, setType] = useState('week')
    const ctx = useContext(AppContext)
    const { userId, player } = ctx.app
    const history = useHistory()
    const ref = useRef(null);
    const [needScroll, setNeedScroll] = useState(false)

    useEffect(() => {
        if(userId) {
            ym('reachGoal', 'RATINGS_SCREEN')
            axios.get(`${ENDPOINT}getRatingByBetPlayer/${player._id}?mode=top`)
                .then(resp => {
                    if(resp.data) {
                        setData(resp.data)
                    } else {
                        setData({monthBank: [], seasonBank: [], weekBank: []})
                    }
                })
        }
    }, [userId])

    const ratingData = data && data[type+'Bank'] ? [...data[type+'Bank']] : []

    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, [ref, type, needScroll])

    return  <div className="mean">
                {data ? (
                    <div className='ratings'>
                        <div className='types'>
                            {Object.entries(types).map((t, i) => (
                                <div
                                    key={i}
                                    className={'smooth'+(type === t[0] ? ' active' : '')}
                                    onClick={() => setType(t[0])}
                                ><span>{t[1]}</span></div>
                            ))}
                        </div>

                        <div className='list'>
                            {ratingData.map((r, i) => {
                                if (!needScroll) setNeedScroll(true)
                                return [
                                    r.pos > 10 ? <div className={'points'}>...</div> : null,
                                    <div
                                        className={`glass${r.appuserId === userId ? ' user' : ''}`}
                                        key={i}
                                        id={r.appuserId}
                                        ref={r.appuserId === userId ? ref : null}
                                    >
                                        <div className='pos'>{r.pos}.</div>
                                        <div className='player'>{r.profile.name}</div>
                                        <div className='value'>{toDigits(r.balance)}</div>
                                    </div>
                                ]
                            })}
                        </div>
                    </div>
                ) : null}
                <Loader shown={!data} />
            </div>
}

export default Ratings
