import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import AppContext from '../ctx'

import Loader from '../Loader'

import { Button } from 'primereact/button'

import './style.scss'
import './medias.scss'

import axios from 'axios'
import { ENDPOINT } from '../env'

import moment from 'moment'

import ym from 'react-yandex-metrika'

const outcomes = {
    'w1': 'победа хозяев',
    'wx': 'ничья',
    'w2': 'победа гостей'
}

const History = () => {
    const [data, setData] = useState(null)
    const ctx = useContext(AppContext)
    const { userId } = ctx.app
    const history = useHistory()

    useEffect(() => {
        if(userId) {
            ym('reachGoal', 'HISTORY_SCREEN')
            axios.get(`${ENDPOINT}getBetPlayerHistory/${userId}`)
                .then(resp => {
                    if(resp.data && resp.data.history) {
                        setData(resp.data.history)
                    }
                })
        }
    }, [userId])

    return  <div className='mean'>
                {data ? data.length ? (
                    <div className='history'>
                        {data.map((bet, i) => (
                            <div className='glass bet' key={i}>
                                <div className='date'>{moment(bet.match.date, 'YY-MM-DD').format('DD.MM')}</div>
                                <div className='teams'>
                                    <div><span>{bet.match.scores && bet.match.scores.full ? bet.match.scores.full.home : '-'}</span>{bet.match.home.name}</div>
                                    <div><span>{bet.match.scores && bet.match.scores.full ? bet.match.scores.full.away : '-'}</span>{bet.match.away.name}</div>
                                </div>
                                <div className={'factor '+(bet.success ? 'won' : bet.success === false ? 'lost' : '')}>
                                    <span>ваш прогноз:</span>
                                    <b>{outcomes[bet.betType]}</b>
                                    <div className='smooth'>
                                        <span>{bet.factor}</span>
                                    </div>
                                </div>

                                <div className='outcome'>
                                    {bet.success !== null ? (
                                        <div className='glass'>
                                            <b className={bet.success ? 'won' : 'lost'}>
                                                <i className={`pi pi-${bet.success ? 'check' : 'times'}-circle`}></i>
                                            </b>
                                            <span>{bet.success ? '+' : '-'} {bet.success ? bet.total : bet.amount}</span>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="history">
                        <div className='non-ideal glass'>
                            <i className='pi pi-history'></i>
                            <div className='title'>вы ещё не делали прогнозов, иcтория пуста</div>
                            <Button label='Сделать первый прогноз' onClick={() => history.push(`/${userId}`)} />
                        </div>
                    </div>
                ) : null}
                <Loader shown={!data} />
            </div>
}

export default History
