import {defaultAchievements} from "../defaultAchievements";
import React, {useContext} from "react";
import AppContext from "../../ctx";
import {Button} from "primereact/button";
import {useHistory} from "react-router-dom";

import './style.scss'

const NewAchievementsModal = ({ setNewAchiev }) => {
    const ctx = useContext(AppContext)
    const { userId, achievements } = ctx.app
    const history = useHistory()
    const aid = window.location.pathname.split('/')[3]

    return <div className="new-achievement">
        {achievements?.betachievements?.length ? (
            achievements?.betachievements.filter(ba => ba._id === aid).map(achiev => {
                return [
                    <div className={'notification'}>У вас новое достижение!</div>,
                    <div className={'glass'} key={achiev._id} onClick={() => {
                        setNewAchiev()
                        history.push(`/${userId}/achievements`)
                    }}>
                        <img src={require(`../../assets/${defaultAchievements[achiev.scope]?.[achiev.sampleType]?.['icon']?.['lvl' + (achiev.stripe > 0 ? 1 : 0)]}`)}/>
                        <div className={'lvl-info'}>Уровень {achiev.stripe + 1}</div>
                        <div className={'description'}>
                            <span>{defaultAchievements[achiev.scope]?.[achiev.sampleType]?.description?.['lvl' + (achiev.stripe > 0 ? 1 : 0)].replace('value', achiev.value)}</span>
                            <div className={'estimated'}>
                                <div className='estimated-bonus'>
                                    {achiev.totalPrize}
                                    <img src={require('../../assets/coin_small.png')}/>
                                </div>
                                <div className='estimated-bar'>
                                    <div
                                        className='estimated-value'
                                        style={{width: `${achiev.qty / (achiev.remains + achiev.qty) * 100}%`}}
                                    >
                                    </div>
                                    <div className={'estimated-title'}>до следующего уровня:</div>
                                </div>
                                <div className='estimated-indicator'>{achiev.qty} / {achiev.remains + achiev.qty}</div>
                            </div>
                        </div>
                </div>]

            })
        ) : null}
    </div>
}

export default NewAchievementsModal