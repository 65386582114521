import React from 'react'

import Lottie from 'react-lottie'
import * as animationData from '../assets/shirts.json'

import './style.scss'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    speed: 0.5
}

const Loader = ({ shown }) => {
    return  <div className={'loader glass'+(shown ? ' shown' : '')}>
                <Lottie
                    options={defaultOptions}
                    width={160}
                    height={160}
                />
            </div>
}

export default Loader
