import React, { useState, useContext } from 'react'

import AppContext from '../ctx'

import { Button } from 'primereact/button'

import './item.scss'

import { ENDPOINT, BET_SIZE } from '../env'
import axios from 'axios'
import moment from 'moment'

import ym from 'react-yandex-metrika'

Number.prototype.round = function(places) {
  return +(Math.round(this + "e+" + places)  + "e-" + places);
}

const getTeamImg = obj => {
    const holder = require('../assets/football-club.png')
    return obj.emblem || (obj.club ? obj.club.emblem || holder : holder)
}

const Form = ({ data, teamId }) => {
    const getItemBgd = ({ homeId, awayId, scores }) => {
        if(!scores || !scores.full) {
            return null
        } else {
            const teamSide = homeId === teamId ? 'home' : 'away'
            const oppSide = teamSide === 'home' ? 'away' : 'home'
            const s = {
                home: parseInt(scores.full.home),
                away: parseInt(scores.full.away)
            }

            return s[teamSide] === s[oppSide] ? 'neutral' : s[teamSide] > s[oppSide] ? 'positive' : 'negative'
        }
    }

    return  <div className='form'>
                {data.map((m, i) => (
                    <div
                        key={i}
                        className={`smooth form-item`}
                        style={{
                            background: `var(--${getItemBgd(m)}-bgd)`
                        }}
                    ></div>
                ))}
            </div>

}

const Team = ({ data }) => {
    return  <div className="team">
                <div
                    className='emb'
                    style={{
                        backgroundImage: `url(${getTeamImg(data)})`
                    }}
                >
                </div>
                <div className='name'>{data.name}</div>
                <Form data={[...data.form].reverse()} teamId={data._id} />
            </div>
}

const WinBetNode = ({ bet, data }) => {
    const team = data[bet.includes('1') ? 'home' : 'away']

    return  <div>
                <div
                    className='emb'
                    style={{
                        backgroundImage: `url(${getTeamImg(team)})`
                    }}
                >
                </div>
                <div className='name'>Победа: {team.name}</div>
            </div>
}

const options = ['w1', 'wx', 'w2']

const Item = ({ data, putBet }) => {
    const [bet, setBet] = useState(null)
    const [betsOver, setBetsOver] = useState(false)
    const [posting, setPosting] = useState(false)

    const ctx = useContext(AppContext)
    const { app, patchApp } = ctx
    const { player, estimatedBets } = app

    const handleBtn = () => {
        setPosting(true)
        axios.post(`${ENDPOINT}postBet`, {
            appPlayerId: player._id,
            matchId: data._id,
            betType: bet,
            factor: data.line.chances[bet],
            amount: BET_SIZE,
            total: (BET_SIZE*data.line.chances[bet]).round(2),
            bankType: 'balance',
            express: false
        }).then(resp => {
            ym('reachGoal', 'SUCCESS_BET', {factor: data.line.chances[bet], estimated: (estimatedBets - 1)})
            setPosting(false)
            patchApp([['player', {...player, balance: player.balance - BET_SIZE}], ['estimatedBets', estimatedBets - 1]])
            putBet(bet)
            setBet(null)
        })
    }

    return  <div className='item'>
                <div className='backdrop'>
                    <img src={getTeamImg(data.home)} />
                    <img src={getTeamImg(data.away)} />
                </div>
                <div className={'glass nobet'+(bet || betsOver ? ' transited' : '')}>
                    <div className='info'>
                        <span>{data.matchday.stage.tournament.name} / {data.matchday.stage.title}</span>
                    </div>
                    <div className='teams'>
                        <Team data={data.home} />
                        <Team data={data.away} />
                    </div>

                    <div className='top-info'>
                        <div className='datetime smooth'>
                            <div className='date'>{moment(data.date, 'YY-MM-DD').format('D MMMM')}</div>
                            <div className='time'>{data.time}</div>
                        </div>
                        <div className='history smooth'>
                            <span>история встреч</span>
                            <span>П1: <i>{data.history.w}</i> Н: <i>{data.history.d}</i> П2: <i>{data.history.l}</i> РМ: <i>{data.history.gs}-{data.history.gc}</i></span>
                        </div>
                    </div>
                </div>
                {!data.userBet ? (
                    <div className={'glass wbet'+(!bet ? ' transited' : '')}>
                        {bet ? [
                            <div className="notice">ваш прогноз на матч {data.home.name} vs {data.away.name}:</div>,
                            <div className="subject">{bet.includes('x') ? <div className='name'>ничья</div> : <WinBetNode bet={bet} data={data} />}</div>,
                            <div className="estimate">{`будет списано ${BET_SIZE} баллов, в случае правильного прогноза вернётся ${(BET_SIZE*data.line.chances[bet]).round(2)} баллов`}</div>,
                            <Button
                                icon='pi pi-check-circle'
                                label='Подтвердить прогноз'
                                disabled={posting}
                                loading={posting}
                                onClick={() => handleBtn()}
                            />
                        ] : null}
                    </div>
                ) : null}
                <div className={'glass wbet'+(!betsOver ? ' transited' : '')}>
                    {betsOver ? [
                        <div className="notice">вы израсходовали лимит прогнозов на этой неделе</div>,
                        <div className="estimate"></div>,
                        <Button
                            icon='pi pi-arrow-left'
                            label='Вернуться назад'
                            onClick={() => setBetsOver(!betsOver)}
                        />
                    ] : null}
                </div>
                <div className='btns'>
                    {options.map(o => (
                        <div
                            key={o}
                            className={'btns-item'+(data.userBet && data.userBet === o ? ' posted' : bet === o ? ' selected' : '')}
                            onClick={() => data.userBet ? null : estimatedBets === 0 ? setBetsOver(!betsOver) : setBet(o === bet ? null : o)}
                        >{data.line.chances[o]}</div>
                    ))}
                </div>
            </div>
}

export default Item
