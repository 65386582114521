import React, { useState, useEffect, useRef } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'

import Item from './Item'
import Loader from '../Loader'

import './style.scss'

import axios from 'axios'
import { ENDPOINT } from '../env'

import qs from 'qs'

import ym from 'react-yandex-metrika'

const shuffle = array => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }

    return array
}

const cutCarousel = (arr, query) => {
    let preset
    let output = []
    if(query && query.presetMatch && query.presetMatch.length === 24) {
        preset = arr.find(m => m._id === query.presetMatch)
        if(preset) {
            output.push(preset)
        }
    }

    const shuffled = shuffle(preset ? arr.filter(m => m._id !== preset._id) : [...arr])
    output = output.concat(shuffled)
    return output.slice(0, 20)
}

const Carousel = ({ player }) => {
    const [data, setData] = useState(null)

    const carouselRef = useRef()

    const query = qs.parse(window.location.search.slice(1))

    useEffect(() => {
        if(player && player._id) {
            axios.get(`${ENDPOINT}getPlayerLine/${player._id}${query && query.presetMatch ? '?presetMatch='+query.presetMatch : ''}`)
                .then(resp => {
                    if(resp.data) {
                        ym('reachGoal', 'LINE_DELIVERY')
                        setData(resp.data.length > 20 ? cutCarousel(resp.data, query) : resp.data)
                    } else {
                        setData([])
                    }
                })
        }
    }, [player])

    let startIdx
    if(data && data.length && query && query.presetMatch) {
        const idx = data.findIndex(i => i._id === query.presetMatch)
        if(idx > -1) {
            startIdx = idx
        }
    }

    return  <div className="carousel">
                {data ? data.length ? (
                    <Splide
                        ref={carouselRef}
                        options={{
                            pagination: true,
                            fixedWidth: '100%',
                            focus: 'start',
                            start: startIdx !== null ? startIdx : 0,
                            type: 'loop',
                            arrows: false,
                            gap: '10px',
                            interval: 4500,
                            perMove: 1,
                            updateOnMove: false,
                            waitForTransition: true,
                            clones: 0
                        }}
                    >
                        {data.map((m, i) => (
                            <SplideSlide key={i}>
                                <Item
                                    data={m}
                                    putBet={bet => {
                                        setData(data.map((m, idx) => idx === i ? {...m, userBet: bet} : m))
                                        carouselRef.current.go(i+1)
                                    }}
                                />
                            </SplideSlide>
                        ))}
                    </Splide>
                ) : (
                    <div className='non-ideal glass'>
                        <i className='pi pi-calendar'></i>
                        <div className='title'>нет доступных для прогноза матчей на этой неделе</div>
                    </div>
                ) : null}
                <Loader shown={!data} />
            </div>
}

export default Carousel
