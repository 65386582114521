import React, {useEffect, useState} from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import AppContext from './ctx'

import { YMInitializer } from 'react-yandex-metrika'

import PrimeReact from 'primereact/api'

import Home from './Home'
import Top from './Top'
import History from './History'
import InfoBar from './InfoBar'
import Achievements from './Achievements'
import Ratings from './Ratings'

import "primereact/resources/primereact.min.css"
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primeicons/primeicons.css'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import './app.scss'

import 'moment/locale/ru'
import axios from "axios";
import {ENDPOINT} from "./env";
import NewAchievementsModal from "./Achievements/NewAchievementsModal";

PrimeReact.ripple = true

const App = () => {
    const [app, setApp] = useState({modal: 'welcomeBalance'})
    const [newAchiev, setNewAchiev] = useState(false)

    const uid = window.location.pathname.split('/')[1]

    useEffect(() => {
        if(app.needInit && app.player) {
            patchApp([['needInit', false]])
        } else if(app && !app.needInit) {
            if (!app.player) {
                axios.get(`${ENDPOINT}getBetPlayerData/${uid}`)
                    .then(resp => {
                        if (!resp.data || (!resp.data.success && !resp.data._id)) {
                            patchApp([['needInit', true]])
                        } else {
                            patchApp([['player', resp.data], ['estimatedBets', resp.data.balance / 100], ['userId', uid]])
                        }
                    })
            }
            if (app.player && !app.achievements) {
                axios.get(`${ENDPOINT}getBetPlayerAchievements/${uid}`)
                    .then(resp => {
                        if (!resp.data || (!resp.data.success && !resp.data._id)) {
                            patchApp([['achievements', []]])
                        } else {
                            patchApp([['achievements', resp.data]])
                        }
                    })
            }
        }
    }, [app])

    useEffect(() => {
        if (window.location.pathname.split('/')[2] === 'achievements' && app.achievements && window.location.pathname.split('/')[3]) {
            setNewAchiev(true)
        } else setNewAchiev(false)
    }, [window.location.pathname, app.achievements])

    const patchApp = arr => {
        const obj = {...app}
        for(let pair of arr) {
            obj[pair[0]] = pair[1]
        }

        setApp(obj)
    }

    const TopNode = app.player ? <Top player={app.player} bestBet={app.achievements?.bestBet}/> : null

    return  <AppContext.Provider value={{app, patchApp}}>
                    <Router>
                        <Switch>
                            <Route path="/:user/achievements/:new" exact={true}>
                                <div className='fantasy' style={{height: '32vh'}}>
                                    <NewAchievementsModal setNewAchiev={() => setNewAchiev(false)}/>
                                </div>
                            </Route>
                            <div className='fantasy'>
                                {(TopNode || null)}
                                <div className='screen'>
                                    {app.player && !newAchiev ? <InfoBar estimatedBets={app.estimatedBets || 0} userId={app.userId} key='bar' /> : null}
                                    <Route path="/:user/history" exact={true}>
                                        <History />
                                    </Route>

                                    <Route path="/:user/achievements" exact={true}>
                                        <Achievements />
                                    </Route>

                                    <Route path="/:user/ratings" exact={true}>
                                        <Ratings />
                                    </Route>

                                    <Route path="/:user" exact={true}>
                                        <Home />
                                    </Route>

                                    {/*<Route>
                                        Error
                                    </Route>*/}
                                </div>
                            </div>
                        </Switch>
                    </Router>

                    <YMInitializer
                        accounts={[94542345]}
                        version='2'
                        options={{
                            clickmap: true,
                            trackLinks: true,
                            accurateTrackBounce: true,
                            webvisor: true,
                            trackHash: true,
                        }}
                    />
            </AppContext.Provider>
}

export default App
