import React, { useContext } from 'react'
import AppContext from '../ctx'

import Init from './Init'
import Carousel from '../Carousel'

import './style.scss'

const Home = () => {
    const ctx = useContext(AppContext)

    const { player, needInit } = ctx.app

    const uid = window.location.pathname.split('/')[1]

    return  needInit ? <Init userId={uid} /> : [
                <div className='mean' key='mean'>
                    <Carousel player={player} />
                </div>
            ]
}

export default Home
