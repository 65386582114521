import React, { useState, useContext, useEffect } from 'react'
import AppContext from '../ctx'

import { Checkbox } from 'primereact/checkbox'
import { Button } from 'primereact/button'

import axios from 'axios'
import { ENDPOINT } from '../env'

import ym from 'react-yandex-metrika'

const Init = ({ userId }) => {
    const [ageBox, setAgeBox] = useState(false)
    const [policyBox, setPolicyBox] = useState(false)
    const [progress, setProgress] = useState(false)

    useEffect(() => {
        ym('reachGoal', 'INIT_SCREEN')
    }, [])

    const ctx = useContext(AppContext)

    const handleAgreement = () => {
        setProgress(true)
        axios.post(`${ENDPOINT}initPlayer`, {
            userId: userId
        }).then(resp => {
            if(resp.data) {
                ym('reachGoal', 'SUCCESS_INIT')
                const initedObject = {
                    ...resp.data.created,
                    profile: resp.data.profile
                }

                ctx.patchApp([['player', initedObject], ['estimatedBets', 10], ['modal', 'welcomeBalance']])
            }
            setProgress(false)
        }).catch(err => {
            setProgress(false)
        })
    }

    return  <div className='init-dialog'>
                <div className='title'>Добро пожаловать в</div>
                <div className='brand'>fantasy!</div>
                <div className='description'>чтобы принять участие в конкурсе, пожалуйста подтвердите, что:</div>

                <div className='glass'>
                    <div className='boxes'>
                        <div onClick={() => setAgeBox(!ageBox)}>
                            <Checkbox id='ageBox' checked={ageBox} />
                            <label htmlFor='ageBox'>вам уже есть 18 лет</label>
                        </div>

                        <div onClick={() => setPolicyBox(!policyBox)}>
                            <Checkbox id='policyBox' checked={policyBox} />
                            <label htmlFor='policyBox'>вы соглашаетесь с политикой обработки персональных данных</label>
                        </div>
                    </div>
                </div>

                <Button disabled={progress || !ageBox || !policyBox} loading={progress} label='Принять участие' onClick={() => handleAgreement()} />
            </div>
}

export default Init
