import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import AppContext from '../ctx'

import Loader from '../Loader'

import './style.scss'

import { Button } from 'primereact/button'
import {defaultAchievements} from "./defaultAchievements";

const Achievements = () => {
    const ctx = useContext(AppContext)
    const { userId, achievements } = ctx.app
    const history = useHistory()

    return  <div className="mean">
                <div className="achievements">
                    {achievements?.betachievements?.length ? (
                        achievements?.betachievements.map(achiev => {
                            return <div className={'glass'} key={achiev._id}>
                                <img src={require(`../assets/${defaultAchievements[achiev.scope]?.[achiev.sampleType]?.['icon']?.['lvl' + (achiev.stripe > 0 ? 1 : 0)]}`)}/>
                                <div className={'lvl-info'}>Уровень {achiev.stripe + 1}</div>
                                <div className={'description'}>
                                    <span>{defaultAchievements[achiev.scope]?.[achiev.sampleType]?.description?.['lvl' + (achiev.stripe > 0 ? 1 : 0)].replace('value', achiev.value)}</span>
                                    <div className={'estimated'}>
                                        <div className='estimated-bonus'>
                                            {achiev.totalPrize}
                                            <img src={require('../assets/coin_small.png')}/>
                                        </div>
                                        <div className='estimated-bar'>
                                            <div
                                                className='estimated-value'
                                                style={{width: `${achiev.qty / (achiev.remains + achiev.qty) * 100}%`}}
                                            >
                                            </div>
                                            <div className={'estimated-title'}>до следующего уровня:</div>
                                        </div>
                                        <div className='estimated-indicator'>{achiev.qty} / {achiev.remains + achiev.qty}</div>
                                    </div>
                                </div>
                            </div>
                        })
                    ) : (
                        <div className='non-ideal glass'>
                            <i className='pi pi-star'></i>
                            <div className='title'>тут пока пусто, но есть к чему стремиться!</div>
                            <Button label='Сделать прогнозы' onClick={() => history.push(`/${userId}`)} />
                        </div>
                    )}
                </div>
                <Loader shown={!achievements} />
            </div>
}

export default Achievements
